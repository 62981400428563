<template>
  <main id="app">

    <nav class="navbar navbar-expand-lg navbar-light bg-white py-3" style="display: none;">
      <div class="container px-5">
        <a class="navbar-brand" href=""><span class="fw-bolder text-primary"></span></a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 small fw-bolder">
            <li class="nav-item"><router-link to="/" class="nav-link">HOME</router-link></li>
            <li class="nav-item"><router-link to="curriculum" class="nav-link">CURRÍCULO</router-link></li>
          </ul>
        </div>
      </div>
    </nav>

    <router-view/>

  </main>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>

@import "assets/css/styles.css";
@import "assets/css/theme.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a {
  text-decoration: none;
  color: inherit;
}
</style>
